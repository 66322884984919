<div class="footer text-light pt-4 text-center">
  <div class="container">
    <div class="row">
      <div class="col">
        <a class="d-inline-block mb-4" routerLink="/">
          <img
            ngSrc="assets/images/footer/brand-moura-ya.webp"
            width="88"
            height="24"
            alt="Moura Ya"
          />

          <h3 class="visually-hidden">Marca Moura Ya</h3>
        </a>

        <span class="spacer"></span>

        <a
          class="d-inline-block mb-4"
          href="https://www.moura.com.ar/"
          target="_blank"
        >
          <img
            ngSrc="assets/images/footer/brand-moura.webp"
            width="90"
            height="32"
            alt="Servicio Oficial de Moura"
          />

          <h3 class="visually-hidden">Marca Baterías Moura</h3>
        </a>

        <br />

        <ul class="d-inline-block mb-4 mt-0 ps-0 opacity-75">
          <li class="d-inline-block mx-1">
            <a
              class="text-light btn btn-sm btn-link"
              routerLink="/sobre"
              (click)="
                sendEvent.emit({
                  footer_item: 'Sobre'
                })
              "
            >
              <strong>Sobre</strong>
            </a>
          </li>
          <li class="d-inline-block mx-1">
            <a
              class="text-light btn btn-sm btn-link"
              routerLink="/faq"
              (click)="
                sendEvent.emit({
                  footer_item: 'FAQ'
                })
              "
            >
              <strong>FAQ</strong>
            </a>
          </li>
          <li class="d-inline-block mx-1">
            <a
              class="text-light btn btn-sm btn-link"
              href="https://www.moura.com.ar/blog"
              target="_blank"
              (click)="
                sendEvent.emit({
                  footer_item: 'Blog'
                })
              "
            >
              <strong>Blog</strong>
            </a>
          </li>
          <li class="d-inline-block mx-1" *ngIf="applicationIsActive">
            <a
              class="text-light btn btn-sm btn-link"
              routerLink="/contacto"
              (click)="
                sendEvent.emit({
                  footer_item: 'Contato'
                })
              "
            >
              <strong>Contacto</strong>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="border-top border-light mb-4 opacity-25"></div>

  <div class="container">
    <div class="row">
      <div class="col">
        <ul class="social d-inline-block mb-4 mt-0 rounded ps-0">
          <li class="d-inline-block">
            <a
              class="facebook position-relative d-block rounded-start"
              href="https://facebook.com/bateriasmoura.arg"
              target="_blank"
            >
              <img
                class="position-absolute top-50 start-50 translate-middle"
                ngSrc="assets/images/footer/icn-facebook.svg"
                width="24"
                height="24"
                alt="Facebook"
              />
            </a>
          </li>
          <li class="d-inline-block">
            <a
              class="instagram position-relative d-block"
              href="https://instagram.com/bateriasmoura.arg"
              target="_blank"
            >
              <img
                class="position-absolute top-50 start-50 translate-middle"
                ngSrc="assets/images/footer/icn-instagram.svg"
                width="24"
                height="24"
                alt="Instagram"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="border-top border-light mb-4 opacity-25"></div>

  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <div class="mb-4 opacity-75">
          <span class="d-block mb-2">Fábrica Moura Argentina</span>

          <a
            class="text-light d-block mb-2"
            href="https://maps.app.goo.gl/UcxyVCibH7FcMd648"
            target="_blank"
          >
            Calle 3 Nº 1188 y Calle del Canal
          </a>

          <a
            class="d-block text-light mb-2"
            href="https://maps.app.goo.gl/UcxyVCibH7FcMd648"
            target="_blank"
          >
            Parque Industrial Pilar - Ruta 8, Km. 60 (CP 1629)
          </a>
          <a class="d-none" href="tel:+5408003459953">08003459953</a>
        </div>
      </div>
      <div class="col-xs-12 col-md-6">
        <div class="mb-4 opacity-75">
          <span class="d-block mb-2">
            Copyright © {{ currentYear }} - Acumuladores Moura S. A.
          </span>

          <span class="d-block mb-2">CNPJ: 01.098.290/0001-10</span>

          <span class="d-block mb-2">Todos los derechos reservados.</span>
        </div>
      </div>
    </div>
  </div>
</div>
