import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Testimonial } from '../_interfaces/testimonial';

@Injectable({
  providedIn: 'root',
})
export class TestimonialsService {
  private model = 'depoimentos';

  constructor(private httpClient: HttpClient) {}

  public all(
    apiUrl: string,
    apiKey: string,
    apiVersion: string,
    limit: string,
    withPicture: string
  ): Observable<Testimonial[]> {
    const headers = new HttpHeaders().set('x-api-key', apiKey);

    const params = new HttpParams()
      .set('limit', limit)
      .set('withPicture', withPicture);

    return this.httpClient.get<Testimonial[]>(
      `${apiUrl}/${apiVersion}/${this.model}`,
      {
        headers,
        params,
      }
    );
  }
}
