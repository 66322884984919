import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FacebookShoppingProduct } from '../_interfaces/facebook-shopping-product';

@Injectable({
  providedIn: 'root',
})
export class FacebookShoppingService {
  private model = 'facebook-shopping/product';

  constructor(private httpClient: HttpClient) {}

  public getProductDetails(
    apiUrl: string,
    apiKey: string,
    apiVersion: string,
    id: string
  ): Observable<FacebookShoppingProduct> {
    const headers = new HttpHeaders().set('x-api-key', apiKey);

    return this.httpClient.get<FacebookShoppingProduct>(
      `${apiUrl}/${apiVersion}/${this.model}/${id}`,
      { headers }
    );
  }
}
