import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() applicationIsActive: boolean;
  @Input() showPhoneNumber: boolean;
  @Input() showWhatsAppButton: boolean;
  @Input() sidebarHiding: boolean;
  @Input() sidebarShow: boolean;
  @Input() sidebarShowing: boolean;

  @Input() phoneNumber: string;
  @Input() whatsAppWelcome: string;

  @Output() sendCallEventSidebar = new EventEmitter();
  @Output() sendEvent = new EventEmitter();
  @Output() sidebarToggle = new EventEmitter();
}
