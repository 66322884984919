<app-header
  [applicationIsActive]="applicationIsActive"
  [showPhoneNumber]="showPhoneNumber$"
  [showWhatsAppButton]="showWhatsAppButton$"
  [pageTitle]="pageTitle$ | async"
  [phoneNumber]="phoneNumber$"
  [whatsAppWelcome]="whatsAppWelcome$ | async"
  (sendCallEventA)="
    sendEvent('click phone', {
      clicked_phone: 'header',
      phone_number: phoneNumber$
    })
  "
  (sendCallEventB)="
    sendEvent('click phone', {
      clicked_phone: 'header',
      phone_number: phoneNumber$
    })
  "
  (sendEvent)="sendEvent('click whatsapp', $event)"
  (sidebarToggle)="handleSidebarToggle()"
></app-header>

<app-sidebar
  [applicationIsActive]="applicationIsActive"
  [showPhoneNumber]="showPhoneNumber$"
  [showWhatsAppButton]="showWhatsAppButton$"
  [sidebarHiding]="sidebarHiding$ | async"
  [sidebarShow]="sidebarShow$"
  [sidebarShowing]="sidebarShowing$ | async"
  [phoneNumber]="phoneNumber$"
  [whatsAppWelcome]="whatsAppWelcome$ | async"
  (sendCallEventSidebar)="
    sendEvent('click phone', {
      clicked_phone: 'sidebar',
      phone_number: phoneNumber$
    })
  "
  (sendEvent)="sendEvent('click whatsapp', $event)"
  (sidebarToggle)="handleSidebarToggle($event)"
></app-sidebar>

<app-sidebar-backdrop
  [sidebarBackdropHiding]="sidebarBackdropHiding$ | async"
  [sidebarBackdropShow]="sidebarBackdropShow$ | async"
  (sidebarToggle)="handleSidebarToggle()"
></app-sidebar-backdrop>

<router-outlet></router-outlet>

<app-footer
  [applicationIsActive]="applicationIsActive"
  (sendEvent)="sendEvent('footer item click', $event)"
></app-footer>

<app-spinning [spinningStatus]="spinningStatus$ | async"></app-spinning>

<div
  id="google-merchants-metadata"
  itemscope
  itemtype="http://schema.org/Product"
  style="display: none"
  *ngIf="googleMerchantsProduct$.product"
>
  <span>{{ googleMerchantsProduct$.product?.offerId }}</span>

  <span itemprop="description">
    {{ googleMerchantsProduct$.product?.description }}
  </span>

  <span itemprop="image">{{ googleMerchantsProduct$.product?.imageLink }}</span>

  <span itemprop="name">{{ googleMerchantsProduct$.product?.title }}</span>

  <div itemscope itemprop="offers" itemtype="http://schema.org/Offer">
    <meta
      itemprop="priceCurrency"
      content="{{ googleMerchantsProduct$.product?.price?.currency }}"
    />

    <span itemprop="price">
      $ {{ googleMerchantsProduct$.product?.price?.value }}
    </span>
  </div>

  <span>{{ googleMerchantsProduct$.product?.availability }}</span>

  <span>{{ googleMerchantsProduct$.product?.brand }}</span>

  <span>{{ googleMerchantsProduct$.product?.channel }}</span>

  <span>{{ googleMerchantsProduct$.product?.condition }}</span>

  <span>{{ googleMerchantsProduct$.product?.googleProductCategory }}</span>

  <span>{{ googleMerchantsProduct$.product?.mpn }}</span>
</div>
