declare const dataLayer: any;

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  public push(event) {
    if (isPlatformBrowser(this.platformId) && window['dataLayer']) {
      return dataLayer.push(event);
    } else {
      return [].push(event);
    }
  }
}
