import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'sobre',
    loadChildren: () => import('./about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'contacto',
    loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'entrega/:id',
    loadChildren: () => import('./delivery/delivery.module').then((m) => m.DeliveryModule),
  },
  {
    path: 'facebook-shopping',
    loadChildren: () => import('./facebook-shopping/facebook-shopping.module').then((m) => m.FacebookShoppingModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'google-shopping',
    loadChildren: () => import('./google-shopping/google-shopping.module').then((m) => m.GoogleShoppingModule),
  },
  {
    path: 'baterias-pesadas',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'extraviado',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  {
    path: 'solicitud',
    loadChildren: () => import('./order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'politica-de-privacidad',
    loadChildren: () => import('./privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'pasos',
    loadChildren: () => import('./steps/steps.module').then((m) => m.StepsModule),
  },
  {
    path: 'terminos-y-condiciones',
    loadChildren: () => import('./terminos-y-condiciones/terminos-y-condiciones.module').then((m) => m.TerminosYCondicionesModule),
  },
  {
    path: 'condiciones',
    loadChildren: () => import('./terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'whats',
    loadChildren: () => import('./whats/whats.module').then((m) => m.WhatsModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'home',
    pathMatch: 'full',
    redirectTo: '',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'extraviado',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      errorHandler: (error) => {
        console.error(error);

        throw error;
      },
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
