import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinning',
  templateUrl: './spinning.component.html',
  styleUrls: ['./spinning.component.scss'],
})
export class SpinningComponent {
  @Input() spinningStatus: boolean | null;
}
