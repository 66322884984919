import { CarouselModule } from 'ngx-owl-carousel-o';

import { LayoutModule } from '@angular/cdk/layout';
import {
  isPlatformBrowser,
  NgOptimizedImage,
  registerLocaleData,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { ComponentsModule } from './_components/components.module';
import { FooterComponent } from './_components/footer/footer.component';
import { HeaderComponent } from './_components/header/header.component';
import { SidebarBackdropComponent } from './_components/sidebar-backdrop/sidebar-backdrop.component';
import { SidebarComponent } from './_components/sidebar/sidebar.component';
import { SpinningComponent } from './_components/spinning/spinning.component';
import { Format0800PhoneNumberPipe } from './_pipes/format-0800-phone-number.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

declare global {
  interface Window {
    _mfq: Array<any>;
  }
}

registerLocaleData(localePt, 'es');

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    Format0800PhoneNumberPipe,
    HeaderComponent,
    SidebarBackdropComponent,
    SidebarComponent,
    SpinningComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({
      appId: 'serverApp',
    }),
    CarouselModule,
    ComponentsModule,
    GoogleMapsModule,
    HttpClientModule,
    LayoutModule,
    MatButtonModule,
    NgOptimizedImage,
  ],
  providers: [
    Meta,
    Title,
    {
      provide: LOCALE_ID,
      useValue: 'es',
    },
    {
      provide: Window,
      useValue: isPlatformBrowser(PLATFORM_ID) ? window : Window,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
