import { Request } from 'express';

import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request
  ) {}

  public getHostname(): string {
    if (isPlatformServer(this.platformId) && this.request != null) {
      var forwardedHostHeader = this.request.header('x-forwarded-host');

      if (!forwardedHostHeader) return this.request.hostname;
      else return forwardedHostHeader;
    } else if (isPlatformBrowser(this.platformId)) {
      return window.location.hostname;
    }
  }

  public getPath() {
    if (isPlatformServer(this.platformId) && this.request != null) {
      return this.request.path;
    } else if (isPlatformBrowser(this.platformId)) {
      return window.location.pathname;
    }
  }
}
