import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../environments/environment';
import { FacebookShoppingProduct } from './_interfaces/facebook-shopping-product';
import { GoogleMerchantsProduct } from './_interfaces/google-merchants-product';
import { DataLayerService } from './_services/data-layer.service';
import { FacebookShoppingService } from './_services/facebook-shopping.service';
import { GoogleMerchantsService } from './_services/google-merchants.service';
import { InterfaceService } from './_services/interface.service';
import { RequestService } from './_services/request.service';
import { SessionService } from './_services/session.service';
import { StoreService } from './_services/store.service';
import { SupportScriptsService } from './_services/support-scripts.service';
import { TestimonialsService } from './_services/testimonials.service';
import { WhatsAppService } from './_services/whatsapp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  /* ------------------------------------------------------------------------ */
  /* Private ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Methods ---------------------------------------------------------------- */
  private subscriptions: Subscription;

  /* Strings ---------------------------------------------------------------- */
  private leadSource$: string;
  private utmCampaign$: string;
  private utmMedium$: string;
  private utmSource$: string;

  /* ------------------------------------------------------------------------ */
  /* Public ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Objects ---------------------------------------------------------------- */
  public facebookShoppingProduct$: FacebookShoppingProduct;
  public googleMerchantsProduct$: GoogleMerchantsProduct;

  /* Booleans --------------------------------------------------------------- */
  public sidebarBackdropHiding$: Observable<boolean>;
  public sidebarBackdropShow$: Observable<boolean>;
  public sidebarHiding$: Observable<boolean>;
  public sidebarShowing$: Observable<boolean>;
  public spinningStatus$: Observable<boolean>;

  public showPhoneNumber$: boolean;
  public showWhatsAppButton$: boolean;
  public sidebarShow$: boolean;
  public userFromHeavyVehicle$: boolean;

  public applicationIsActive: boolean;

  /* Strings ---------------------------------------------------------------- */
  public pageTitle$: Observable<string>;
  public whatsAppWelcome$: Observable<string>;

  public phoneNumber$: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private dataLayerService: DataLayerService,
    private facebookShoppingService: FacebookShoppingService,
    private googleMerchantsService: GoogleMerchantsService,
    private interfaceService: InterfaceService,
    private location: Location,
    private renderer2: Renderer2,
    private requestService: RequestService,
    private router: Router,
    private sessionService: SessionService,
    private storeService: StoreService,
    private supportScriptsService: SupportScriptsService,
    private testimonialsService: TestimonialsService,
    private whatsAppService: WhatsAppService
  ) {
    this.subscriptions = new Subscription();

    this.leadSource$ = '';
    this.utmCampaign$ = '';
    this.utmMedium$ = '';
    this.utmSource$ = '';

    this.facebookShoppingProduct$ = {} as FacebookShoppingProduct;
    this.googleMerchantsProduct$ = {} as GoogleMerchantsProduct;

    this.sidebarBackdropHiding$ = this.interfaceService.sidebarBackdropHiding$;
    this.sidebarBackdropShow$ = this.interfaceService.sidebarBackdropShow$;
    this.sidebarHiding$ = this.interfaceService.sidebarHiding$;
    this.sidebarShowing$ = this.interfaceService.sidebarShowing$;
    this.spinningStatus$ = this.interfaceService.spinningStatus$;

    this.showPhoneNumber$ = false;
    this.showWhatsAppButton$ = false;
    this.sidebarShow$ = false;
    this.userFromHeavyVehicle$ = false;

    this.applicationIsActive = false;

    this.pageTitle$ = this.storeService.pageTitle$;
    this.whatsAppWelcome$ = this.storeService.whatsAppWelcome$;

    this.phoneNumber$ = '';
  }

  ngOnInit(): void {
    this.applicationIsActive = /true/.test(environment.enabled);

    this.supportScriptsService.injectScripts(this.renderer2, this.document);

    this.subscriptions.add(
      this.storeService.leadSource$.subscribe(
        (value) => (this.leadSource$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.utmCampaign$.subscribe(
        (value) => (this.utmCampaign$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.utmMedium$.subscribe(
        (value) => (this.utmMedium$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.utmSource$.subscribe(
        (value) => (this.utmSource$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.facebookShoppingProduct$.subscribe(
        (value) => (this.facebookShoppingProduct$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.googleMerchantsProduct$.subscribe(
        (value) => (this.googleMerchantsProduct$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.showPhoneNumber$.subscribe(
        (value) => (this.showPhoneNumber$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.showWhatsAppButton$.subscribe(
        (value) => (this.showWhatsAppButton$ = value)
      )
    );

    this.subscriptions.add(
      this.interfaceService.sidebarShow$.subscribe(
        (value) => (this.sidebarShow$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.userFromHeavyVehicle$.subscribe(
        (value) => (this.userFromHeavyVehicle$ = value)
      )
    );

    this.subscriptions.add(
      this.storeService.phoneNumber$.subscribe(
        (value) => (this.phoneNumber$ = value)
      )
    );

    if (isPlatformBrowser(this.platformId)) {
      this.checkLocationHostnameSubstring();

      this.checkUserOrigin();

      this.checkWhatsAppParams();

      this.getTestimonials(
        environment.apis.mouraSearch,
        environment.keys.mouraSearch.acquisition,
        'v1',
        '3',
        'false'
      );

      this.getWhatsAppOpeningHours(
        environment.apis.mouraSearch,
        environment.keys.mouraSearch.acquisition,
        'v1',
        'MOURA_FACIL'
      );

      this.saveURLParams();

      this.saveTheDayOfTheFirstVisit();

      this.extractAndManipulateURLParams();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkLocationHostnameSubstring(): void {
    const hostname = this.requestService.getHostname();
    const hostnameSubstrings = hostname.split('.');
    const pathname = this.location.path(true);

    let params = this.getURLSearchParams();

    if (
      hostnameSubstrings[0] === 'pesadas' ||
      pathname.includes('baterias-pesadas')
    ) {
      this.storeService.updateLeadSource('googlepesadas');
      // this.storeService.updatePhoneNumber('');
      this.storeService.updateUserFromHeavyVehicle(true);
      this.storeService.updateUserFromPartnership(true);
      this.storeService.updateUserOrigin('Veículos Pesados');

      if (
        params.get('utm_source') === 'google' ||
        ['googleadspesadas'].includes(params.get('utm_source'))
      ) {
        this.storeService.updateLeadSource('googleadspesadas');
        // this.storeService.updatePhoneNumber('');
      }

      this.sendUserFrom('Veículos Pesados');
    }
  }

  private checkUserOrigin(): void {
    const hostname = this.requestService.getHostname();
    const hostnameSubstrings = hostname.split('.');

    if (hostnameSubstrings[0] === 'readquirir') {
      this.storeService.updateShowPhoneNumber(false);
      this.storeService.updateShowWhatsAppButton(false);
    }
  }

  private checkWhatsAppParams(): void {
    const params = new URLSearchParams(window.location.search.substring(1));

    const google = params.get('google');
    const xaxis = params.get('xaxis');

    if (google === 'discovery') {
      this.storeService.updateWhatsAppWelcome(
        '¡Hola! Estaba navegando vi el anuncio, necesito una batería.'
      );
    }

    if (xaxis === 'programmatic') {
      this.storeService.updateWhatsAppWelcome(
        'Hola, vi el anuncio y necesito una batería.'
      );
    }
  }

  private extractAndManipulateURLParams(): void {
    const params = this.getURLSearchParams();

    if (params.toString().length === 0) {
      if (
        ['googleadspesadas', 'googlepesadas', 'oferta', 'readquirir'].includes(
          this.leadSource$
        )
      ) {
        return;
      }

      const referrer =
        sessionStorage.getItem('referrer') || this.document.referrer;

      const leadSource = referrer ? 'organico' : 'direto';

      this.storeService.updateLeadSource(leadSource);

      sessionStorage.setItem('referrer', referrer);

      if (leadSource === 'direto') {
        // this.storeService.updatePhoneNumber('');
        // this.storeService.updateWhatsAppWelcome('');
      }

      const session = {
        leadsourceNonDirect: leadSource,
        originNonDirect: this.utmCampaign$,
        timestamp: Date.now(),
        urlParams: '',
        utmMediumNonDirect: this.utmMedium$,
        utmSourceNonDirect: this.utmSource$,
      };

      this.sessionService.addSession(session);

      return;
    }

    const batteryModel = params.get('batteryModel');
    const car = params.get('carro');
    const facebookShopping = params.get('facebookshopping');
    const facebookShoppingID = params.get('productId');
    const googleShopping = params.get('googleshopping');
    const googleShoppingID = params.get('gmid');
    const regionId = params.get('regionId') || params.get('region_id');
    const utmCampaign = params.get('utm_campaign')?.toLocaleLowerCase();
    const utmSource = params.get('utm_source')?.toLocaleLowerCase();

    if (batteryModel) {
      this.storeService.updateBatteryModel(batteryModel);
    }

    if (facebookShoppingID && isPlatformBrowser(this.platformId)) {
      this.getFacebookShoppingProduct(
        environment.apis.mouraSearch,
        environment.keys.mouraSearch.acquisition,
        'v1',
        facebookShoppingID
      );
    }

    if (googleShoppingID && isPlatformBrowser(this.platformId)) {
      this.getGoogleMerchantsProduct(
        environment.apis.mouraSearch,
        environment.keys.mouraSearch.acquisition,
        'v1',
        googleShoppingID,
        regionId
      );
    }

    if (facebookShopping || facebookShoppingID) {
      this.sendUserFrom('Facebook Shopping');
    }

    if (googleShopping || googleShoppingID) {
      this.sendUserFrom('Google Shopping');
    }

    if (facebookShopping) {
      this.dataLayerService.push({
        event: 'Request delivery (Facebook Shopping)',
      });
    }

    if (googleShopping) {
      this.storeService.updateLeadSource('googlemerchantads');
      this.storeService.updateLeadSourceDetails('googlemerchant_formulario');
      this.storeService.updateUserFromGoogleShopping(true);
      // this.storeService.updateUserFromPartnership(true);
      this.storeService.updateUserOrigin('Google Merchants');

      this.dataLayerService.push({
        event: 'Request delivery (Google Shopping)',
      });
    }

    if (googleShopping && !car) {
      this.storeService.updateLeadSource('googlemerchantadsbateria');
    }

    if (googleShopping && !this.userFromHeavyVehicle$) {
      // this.storeService.updatePhoneNumber('');
    }

    if (utmSource === 'organico') {
      this.storeService.updateLeadSource('organico');
      // this.storeService.updatePhoneNumber('');

      this.dataLayerService.push({
        event: 'Request delivery (organico)',
      });
    }

    const isOnHeavyVehicles =
      this.requestService.getHostname().split('.')[0] === 'pesadas' ||
      this.location.path(true).includes('baterias-pesadas');

    if (utmSource === 'google' && !googleShopping && !isOnHeavyVehicles) {
      this.storeService.updateLeadSource('googleadsleves');
      this.storeService.updateLeadSourceDetails('adwords_formulario');
      // this.storeService.updatePhoneNumber('');
      // this.storeService.updateWhatsAppWelcome(encodeURI(''));

      this.dataLayerService.push({
        event: 'Request delivery (googleadsleves)',
      });

      this.insertMouseflow();
    }

    if (utmCampaign && utmCampaign.includes('facebook')) {
      this.storeService.updateLeadSource('adsfacebook');
      this.storeService.updateLeadSourceDetails(
        'ads_facebook_clique_telefônico'
      );
      // this.storeService.updatePhoneNumber('');
      this.storeService.updateUserOrigin('AdsFacebook');
      this.storeService.updateUTMCampaign('adsfacebook');
      // this.storeService.updateWhatsAppWelcome(encodeURI(''));

      this.dataLayerService.push({
        event: 'Request delivery (Ads Facebook)',
      });
    }

    if (utmSource) {
      const utmSourceClean = utmSource.replace(/_(?!.*_)\D*/g, '');
      const utmSourceText = utmSourceClean.replace(/_[^_]*$/g, '');

      let utmSourceOrCampaignNumber: string;

      if (/\d/.test(utmSource)) {
        utmSourceOrCampaignNumber = utmSource.match(/\d+/)[0];
      } else {
        utmSourceOrCampaignNumber = utmCampaign.match(/\d+/)[0];
      }

      this.storeService.updateUTMSource(utmSourceText);
      this.storeService.updateLeadSource(utmSourceText);
      this.storeService.updatePhoneNumber(utmSourceOrCampaignNumber);
    }

    const session = {
      leadsourceNonDirect: this.leadSource$,
      originNonDirect: this.utmCampaign$,
      timestamp: Date.now(),
      utmSourceNonDirect: this.utmSource$,
      utmMediumNonDirect: this.utmMedium$,
      urlParams: sessionStorage.getItem('urlParams'),
    };

    this.sessionService.addSession(session);
  }

  private getGoogleMerchantsProduct(
    apiURL: string,
    apiKey: string,
    apiVersion: string,
    id: string,
    regionId: string
  ): void {
    firstValueFrom(
      this.googleMerchantsService.getProductDetails(
        apiURL,
        apiKey,
        apiVersion,
        id,
        regionId
      )
    )
      .then((response) => {
        let model = null;

        response.product.customAttributes.forEach((objeto) => {
          if (objeto['name'] === 'model') {
            model = objeto['value'];

            return;
          }
        });

        this.storeService.updateWhatsAppWelcome(
          encodeURI(`Hola necesito una batería ${model} para mi auto`)
        );

        return this.storeService.updateGoogleMerchantsProduct(response);
      })
      .catch((_) =>
        this.router.navigateByUrl('extraviado', {
          skipLocationChange: true,
        })
      );
  }

  private getFacebookShoppingProduct(
    apiURL: string,
    apiKey: string,
    apiVersion: string,
    id: string
  ): void {
    firstValueFrom(
      this.facebookShoppingService.getProductDetails(
        apiURL,
        apiKey,
        apiVersion,
        id
      )
    )
      .then((response) => {
        this.storeService.updateWhatsAppWelcome(
          encodeURI(
            `Hola necesito una batería ${response.batteryModel} para mi auto`
          )
        );

        return this.storeService.updateFacebookShoppingProduct(response);
      })
      .catch((_) =>
        this.router.navigateByUrl('extraviado', {
          skipLocationChange: true,
        })
      );
  }

  private getTestimonials(
    apiURL: string,
    apiKey: string,
    apiVersion: string,
    perPage: string,
    withPicture: string
  ): void {
    this.testimonialsService
      .all(apiURL, apiKey, apiVersion, perPage, withPicture)
      .pipe(first())
      .subscribe((response) => {
        this.storeService.updateTestimonials(response);
      });
  }

  private getURLSearchParams(): URLSearchParams {
    const urlPath = sessionStorage.getItem('urlParams')
      ? sessionStorage.getItem('urlParams')
      : window.location.search;

    return new URLSearchParams(urlPath);
  }

  private getWhatsAppOpeningHours(
    apiURL: string,
    apiKey: string,
    version: string,
    type: string
  ): void {
    this.whatsAppService
      .all(apiURL, apiKey, version, type)
      .pipe(first())
      .subscribe((response) => {
        this.storeService.updateShowWhatsAppButton(response.open);
      });
  }

  private insertMouseflow(): void {
    window._mfq = window._mfq || [];

    let script = this.renderer2.createElement('script');

    script.src =
      '//cdn.mouseflow.com/projects/7eca9b85-97db-4e23-b1ad-1eddc4bb066e.js';
    script.type = 'text/javascript';
    script.defer = true;

    this.renderer2.appendChild(this.document.head, script);
  }

  private saveTheDayOfTheFirstVisit(): void {
    const currentDate = new Date();
    const firstVisitDateStored = JSON.parse(
      localStorage.getItem('firstVisitDateStored')
    );

    let firstVisitDate: Date;

    if (firstVisitDateStored) {
      firstVisitDate = new Date(firstVisitDateStored);
    } else {
      firstVisitDate = new Date();

      localStorage.setItem(
        'firstVisitDateStored',
        JSON.stringify(firstVisitDate)
      );
    }

    const differenceInTime = currentDate.getTime() - firstVisitDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    localStorage.setItem('lastVisit', JSON.stringify(differenceInDays));
  }

  private saveURLParams(): void {
    const allowedPaths = ['/', '/solicitud'];

    const url = this.location.path();
    const path = url.split('?')[0] || '/';
    const params = url.split('?')[1] || '';

    const internalRedirect = this.document.referrer.includes(
      this.requestService.getHostname()
    );

    const isOrderWithReferrer =
      this.document.referrer.length > 0 && path === '/solicitud';

    if (internalRedirect) {
      return;
    }

    if (allowedPaths.includes(path) || isOrderWithReferrer) {
      sessionStorage.setItem('urlParams', params);
    }
  }

  private sendUserFrom(value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.dataLayerService.push({
        event: `User from ${value}`,
      });
    }
  }

  public handleSidebarToggle(event?: string): void {
    if (!this.sidebarShow$) {
      this.interfaceService.updateSidebarBackdropShow(true);

      this.interfaceService.updateSidebarShowing(true);

      setTimeout(() => {
        this.interfaceService.updateSidebarShow(true);
        this.interfaceService.updateSidebarShowing(false);
      }, 300);
    } else {
      this.interfaceService.updateSidebarHiding(true);

      setTimeout(() => {
        this.interfaceService.updateSidebarBackdropHiding(true);
      }, 150);

      setTimeout(() => {
        this.interfaceService.updateSidebarHiding(false);
        this.interfaceService.updateSidebarShow(false);

        this.interfaceService.updateSidebarBackdropHiding(false);
        this.interfaceService.updateSidebarBackdropShow(false);
      }, 300);
    }

    if (event) {
      this.sendEvent('menu lateral click', { menu_item: event });
    }
  }

  public sendEvent(event: string, eventData?: Record<string, string>): void {
    this.dataLayerService.push({
      event,
      ...eventData,
    });
  }
}
