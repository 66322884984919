import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format0800PhoneNumber',
})
export class Format0800PhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    const firstPart = value.slice(0, 4);
    const secondPart = value.slice(4, 7);
    const thirdPart = value.slice(7);

    return `${firstPart}-${secondPart}-${thirdPart}`;
  }
}
