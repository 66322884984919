import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() applicationIsActive: boolean;
  @Input() showPhoneNumber: boolean;
  @Input() showWhatsAppButton: boolean;

  @Input() pageTitle: string;
  @Input() phoneNumber: string;
  @Input() whatsAppWelcome: string;

  @Output() sendCallEventA = new EventEmitter();
  @Output() sendCallEventB = new EventEmitter();
  @Output() sendEvent = new EventEmitter();
  @Output() sidebarToggle = new EventEmitter();
}
