<div class="position-relative z-2 bg-white shadow-sm">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <nav class="d-flex align-items-center">
          <a class="brand me-auto" routerLink="/">
            <h1 class="visually-hidden">{{ pageTitle }}</h1>

            <h2 class="visually-hidden">Servicio Oficial de Moura</h2>

            <img
              class="w-100 h-auto"
              ngSrc="assets/images/header/brand-moura-ya-desktop.svg"
              width="237"
              height="46"
              alt="Moura Ya"
            />
          </a>

          <div
            class="wanna-call d-none d-lg-block text-info"
            *ngIf="applicationIsActive && showPhoneNumber"
          >
            <span class="visually-hidden">Pedí tu batería</span>

            <strong class="text-primary ms-1">Llamanos!</strong>

            <a
              class="text-success text-decoration-none ms-1"
              href="tel:+54{{ phoneNumber }}"
              (click)="sendCallEventA.emit()"
            >
              <strong>{{ phoneNumber | format0800PhoneNumber }}</strong>
            </a>
          </div>

          <a
            class="d-lg-none mt-n1 btn btn-green-g text-white"
            href="tel:+54{{ phoneNumber }}"
            *ngIf="applicationIsActive && showPhoneNumber"
            (click)="sendCallEventB.emit()"
          >
            <small
              ><strong>{{ phoneNumber | format0800PhoneNumber }}</strong></small
            >
          </a>

          <a
            class="d-none d-lg-inline ms-2"
            [href]="
              'https://api.whatsapp.com/send?phone=5491140333591&text=' +
              whatsAppWelcome
            "
            target="_blank"
            *ngIf="applicationIsActive && showWhatsAppButton"
            (click)="
              sendEvent.emit({
                button: 'CTA whatsapp'
              })
            "
          >
            <span class="visually-hidden">
              También la podés pedir por WhatsApp
            </span>

            <img
              ngSrc="assets/images/brand-whatsapp@2x.png"
              width="36"
              height="36"
              alt="Marca WhatsApp"
            />
          </a>

          <button
            class="mt-n1 btn btn-light btn-sm ms-2"
            (click)="sidebarToggle.emit()"
          >
            <span class="visually-hidden">Mostrar/ocultar el menú</span>

            <svg
              class="align-middle"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 18v-2h18v2Zm0-5v-2h18v2Zm0-5V6h18v2Z" />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  </div>
</div>
