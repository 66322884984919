import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { AdvantagesComponent } from './advantages/advantages.component';
import { AutomakersComponent } from './automakers/automakers.component';
import { DeliveriesComponent } from './deliveries/deliveries.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { PostsComponent } from './posts/posts.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { WhatsAppComponent } from './whatsapp/whatsapp.component';

@NgModule({
  declarations: [
    AdvantagesComponent,
    AutomakersComponent,
    DeliveriesComponent,
    HowItWorksComponent,
    PostsComponent,
    TestimonialsComponent,
    WhatsAppComponent,
  ],
  imports: [CommonModule, NgOptimizedImage, RouterLink, RouterLinkActive],
  exports: [
    AdvantagesComponent,
    AutomakersComponent,
    DeliveriesComponent,
    HowItWorksComponent,
    PostsComponent,
    TestimonialsComponent,
    WhatsAppComponent,
  ],
})
export class ComponentsModule {}
