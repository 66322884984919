export const environment = {
  apis: {
    mouraDigitalServices: '$MF_DIGITAL_SERVICES_URI',
    mouraFacil: '$MF_API_URI',
    mouraFacilBlog: '$MF_BLOG_API',
    mouraFacilRealTime: '$MF_REALTIME_URI',
    mouraSearch: '$MF_SEARCH_URI',
    mouraServices: '$MF_MOURA_SERVICES_URI',
  },
  enabled: '$MF_ENABLED',
  keys: {
    googleMaps: {
      acquisition: '$MF_GOOGLE_MAPS_API_KEY',
    },
    mouraSearch: {
      acquisition: '$MF_SEARCH_API_KEY_ACQUISITION',
    },
  },
  production: '$MF_PRODUCTION',
  sentry: {
    dsn: '$MF_SENTRY_DSN_API_KEY',
  },
  slugCommit: '$HEROKU_SLUG_COMMIT',
  tokens: {
    mouraFacilRealTime: '$MF_REALTIME_TOKEN',
  },
  version: '$HEROKU_RELEASE_VERSION',
};
