import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GoogleMerchantsProduct } from '../_interfaces/google-merchants-product';

@Injectable({
  providedIn: 'root',
})
export class GoogleMerchantsService {
  private model = 'google-merchant/product';

  constructor(private httpClient: HttpClient) {}

  public getProductDetails(
    apiUrl: string,
    apiKey: string,
    apiVersion: string,
    id: string,
    regionId: string
  ): Observable<GoogleMerchantsProduct> {
    const headers = new HttpHeaders().set('x-api-key', apiKey);

    if (regionId) {
      const params = new HttpParams().set('regionId', regionId);

      return this.httpClient.get<GoogleMerchantsProduct>(
        `${apiUrl}/${apiVersion}/${this.model}/${id}`,
        { headers, params }
      );
    } else {
      return this.httpClient.get<GoogleMerchantsProduct>(
        `${apiUrl}/${apiVersion}/${this.model}/${id}`,
        { headers }
      );
    }
  }
}
