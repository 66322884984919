import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar-backdrop',
  templateUrl: './sidebar-backdrop.component.html',
  styleUrls: ['./sidebar-backdrop.component.scss'],
})
export class SidebarBackdropComponent {
  @Input() sidebarBackdropHiding: boolean;
  @Input() sidebarBackdropShow: boolean;

  @Output() sidebarToggle = new EventEmitter();
}
