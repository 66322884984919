<div
  class="offcanvas offcanvas-end"
  [ngClass]="{
    hiding: sidebarHiding,
    show: sidebarShow,
    showing: sidebarShowing
  }"
  id="offcanvas"
  aria-labelledby="offcanvasLabel"
  tabindex="-1"
>
  <div class="offcanvas-header py-0 ps-2 shadow-sm">
    <h5 class="offcanvas-title visually-hidden" id="offcanvasLabel">
      Moura Ya
    </h5>

    <a
      class="whatsapp position-relative text-decoration-none"
      [href]="
        'https://api.whatsapp.com/send?phone=5491140333591&text=' +
        whatsAppWelcome
      "
      target="_blank"
      *ngIf="applicationIsActive && showWhatsAppButton"
      (click)="
        sendEvent.emit({
          button: 'CTA whatsapp'
        })
      "
    >
      <img
        class="position-absolute top-50 translate-middle-y start-0"
        ngSrc="assets/images/brand-whatsapp@2x.png"
        width="36"
        height="36"
        alt="Marca WhatsApp"
      />

      <span class="d-block">Pedila ahora</span>

      <strong class="d-block">WhatsApp</strong>
    </a>

    <button
      class="btn-close"
      data-bs-dismiss="offcanvas"
      type="button"
      aria-label="Ocultar el menú"
      (click)="sidebarToggle.emit()"
    ></button>
  </div>

  <div class="offcanvas-body p-0">
    <ul class="list-group list-group-flush">
      <a
        class="list-group-item list-group-item-action border-box"
        routerLink="/"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        (click)="sidebarToggle.emit('Home')"
      >
        Home
      </a>

      <a
        class="list-group-item list-group-item-action border-box"
        routerLink="/sobre"
        routerLinkActive="active"
        (click)="sidebarToggle.emit('Sobre el servicio')"
      >
        Sobre el servicio
      </a>

      <a
        class="list-group-item list-group-item-action border-box"
        routerLink="/faq"
        routerLinkActive="active"
        (click)="sidebarToggle.emit('Preguntas frecuentes')"
      >
        Preguntas frecuentes
      </a>

      <a
        class="list-group-item list-group-item-action border-box"
        href="https://www.moura.com.ar/blog"
        target="_blank"
        (click)="sidebarToggle.emit('Nuestro Blog')"
      >
        Nuestro blog
      </a>

      <a
        class="list-group-item list-group-item-action border-box"
        routerLink="/contacto"
        routerLinkActive="active"
        *ngIf="applicationIsActive"
        (click)="sidebarToggle.emit('Hable con nosotros')"
      >
        Hable con nosotros
      </a>

      <a
        class="list-group-item list-group-item-action border-box"
        routerLink="/politica-de-privacidad"
        routerLinkActive="active"
        (click)="sidebarToggle.emit('Política de privacidad')"
      >
        Política de privacidad
      </a>
    </ul>
  </div>
</div>
