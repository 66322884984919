import { Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SupportScriptsService {
  private addGoogleTagManager(renderer2: Renderer2, document: Document): void {
    let script = renderer2.createElement('script');
    script.text = `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-MKG8CLX");
    `;
    script.type = 'text/javascript';
    renderer2.appendChild(document.head, script);
  }

  public injectScripts(renderer2: Renderer2, document: Document): void {
    this.addGoogleTagManager(renderer2, document);
  }
}
