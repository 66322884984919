import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { WhatsApp } from '../_interfaces/whatsapp';

@Injectable({
  providedIn: 'root',
})
export class WhatsAppService {
  private model = 'horarios-whatsapp';

  constructor(private httpClient: HttpClient) {}

  public all(
    apiUrl: string,
    apiKey: string,
    apiVersion: string,
    type: string
  ): Observable<WhatsApp> {
    const headers = new HttpHeaders().set('x-api-key', apiKey);

    const params = new HttpParams().set('scheduleType', type);

    return this.httpClient.get<WhatsApp>(
      `${apiUrl}/${apiVersion}/${this.model}`,
      {
        headers,
        params,
      }
    );
  }
}
