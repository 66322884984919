import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InterfaceService {
  /* ------------------------------------------------------------------------ */
  /* Private ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Booleans --------------------------------------------------------------- */
  private showCitiesModalBS = new BehaviorSubject<boolean>(false);
  private showVehiclesModalBS = new BehaviorSubject<boolean>(false);
  private sidebarBackdropHidingBS = new BehaviorSubject<boolean>(false);
  private sidebarBackdropShowBS = new BehaviorSubject<boolean>(false);
  private sidebarHidingBS = new BehaviorSubject<boolean>(false);
  private sidebarShowBS = new BehaviorSubject<boolean>(false);
  private sidebarShowingBS = new BehaviorSubject<boolean>(false);
  private spinningStatusBS = new BehaviorSubject<boolean>(false);

  /* ------------------------------------------------------------------------ */
  /* Public ----------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Booleans --------------------------------------------------------------- */
  public showCitiesModal$ = this.showCitiesModalBS.asObservable();
  public showVehiclesModal$ = this.showVehiclesModalBS.asObservable();
  public sidebarBackdropHiding$ = this.sidebarBackdropHidingBS.asObservable();
  public sidebarBackdropShow$ = this.sidebarBackdropShowBS.asObservable();
  public sidebarHiding$ = this.sidebarHidingBS.asObservable();
  public sidebarShow$ = this.sidebarShowBS.asObservable();
  public sidebarShowing$ = this.sidebarShowingBS.asObservable();
  public spinningStatus$ = this.spinningStatusBS.asObservable();

  /* ------------------------------------------------------------------------ */
  /* Methods ---------------------------------------------------------------- */
  /* ------------------------------------------------------------------------ */

  /* Booleans --------------------------------------------------------------- */
  public updateShowCitiesModalStatus(value: boolean): void {
    this.showCitiesModalBS.next(value);
  }

  public updateShowVehiclesModalStatus(value: boolean): void {
    this.showVehiclesModalBS.next(value);
  }

  public updateSidebarBackdropHiding(value: boolean): void {
    this.sidebarBackdropHidingBS.next(value);
  }

  public updateSidebarBackdropShow(value: boolean): void {
    this.sidebarBackdropShowBS.next(value);
  }

  public updateSidebarHiding(value: boolean): void {
    this.sidebarHidingBS.next(value);
  }

  public updateSidebarShow(value: boolean): void {
    this.sidebarShowBS.next(value);
  }

  public updateSidebarShowing(value: boolean): void {
    this.sidebarShowingBS.next(value);
  }

  public updateSpinningStatus(value: boolean): void {
    this.spinningStatusBS.next(value);
  }
}
